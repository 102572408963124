import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import I18n from "../../i18n-js/index.js.erb";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useSelector, useDispatch } from "react-redux";
import { setPaymentData } from "../redux/actions/actions";
import clsx from "clsx";
import { useStylesPayment } from "./styles/paymentOptionsStyles";
import { to_eur, currencyToSymbol } from "../../helpers/base";
import PaymentOptions from "./components/paymentoptions/PaymentOptions";

const reservation_code = new URLSearchParams(window.location.search).get(
  "reservation_code"
);

const PRIME_FEE = 1599;

const cardPaymentOption = "card";

let payNowButton = {
  marginTop: "20px",
  float: "right",
};

let cardField = {
  width: "inherit !important",
};

let couponField = {
  border: "1px solid darkgrey",
  padding: "9x 15px",
  borderRadius: "5px",
  verticalAlign: "middle",
  padding: "8px",
  marginLeft: "2%",
};

let referralWarning = {
  display: "none",
  color: "red",
};

let couponMessage = {
  cursor: "pointer",
};

let paymentRequestButtonStyle = {
  type: "default",
  // One of 'default', 'book', 'buy', or 'donate'
  // Defaults to 'default'

  theme: "dark",
  // One of 'dark', 'light', or 'light-outline'
  // Defaults to 'dark'

  height: "40px",
  // Defaults to '40px'. The width is always '100%'.
  padding: 0,
};

function PaymentStep(props) {
  const dispatch = useDispatch();
  const classes = useStylesPayment(props);
  const stripe = useStripe();
  const elements = useElements();
  const FunnelData = useSelector((state) => state.PaymentData);
  const clientSecret = useSelector((state) => state.clientSecret);
  const currentUser = useSelector((state) => state.currentUser);
  const [paymentLoad, setPaymentLoad] = useState(false);
  const [showCouponField, setShowCouponField] = useState(false);
  const [couponInput, setCouponInput] = useState("");
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [cardError, setCardError] = useState(false);
  const [couponError, setCouponError] = useState(false);
  const JWTToken = useSelector((state) => state.JWTToken);
  const acceptConditions = useRef(null);
  const [joinClub, setJoinClub] = useState();
  
  const BASE_URL = props.baseUrl || '';
  const FORM_SOURCE = "Payment step";

  axios.defaults.withCredentials = true;
  axios.defaults.headers.common["Authorization"] = `Bearer ${JWTToken}`;

  const analyticsTrack = (eventType, inputHash) => {
    analytics.track(eventType, { inputHash, form: FORM_SOURCE });
  };

  const selectedPaymentOption = () => {
    return currentUser.role === "travel_agent"
      ? "full_payment"
      : FunnelData.PaymentOption;
  };


  const currentCountry = currentUser?.isInformationComplete?.country_code ? currentUser.isInformationComplete.country_code : 'ES'

  const costlessPayment =
    FunnelData.PaymentOption === "wallet_payment" &&
    FunnelData.PaymentData.pay_now + FunnelData.PaymentData.fee +
    FunnelData.PaymentData.rest_to_pay -
    FunnelData.wallet.balance <=
    0;

  const walletPartialPayment =
    FunnelData.PaymentOption === "wallet_payment" &&
    FunnelData.PaymentData.pay_now + FunnelData.PaymentData.fee +
    FunnelData.PaymentData.rest_to_pay -
    FunnelData.wallet.balance >=
    0;

  const addClubFee = (amount) => {
    return joinClub ? amount + PRIME_FEE : amount;
  }
  const toPayNowAmount = (payment_data) => {
    var fullAmount =
      payment_data.pay_now + payment_data.rest_to_pay + payment_data.fee;

    var partialAmount = payment_data.pay_now + payment_data.fee;

    var walletCreditExceedsPartial =
      payment_data.rest_to_pay +
      payment_data.fee +
      FunnelData.PaymentData.pay_now -
      FunnelData.wallet.balance >=
      0;

    var walletCreditAmount = walletCreditExceedsPartial
      ? payment_data.rest_to_pay +
      payment_data.fee +
      payment_data.pay_now -
      FunnelData.wallet.balance
      : 0;

    var hbDiscountAmount =
      0.7 * (payment_data.rest_to_pay + payment_data.pay_now) +
      payment_data.fee;

    switch (selectedPaymentOption()) {
      case "full_payment":
        return addClubFee(fullAmount);
      case "partial_payment":
        return addClubFee(partialAmount);
      case "wallet_payment":
        return walletCreditAmount;
      case "hb_discount":
        return hbDiscountAmount;
      default:
        return addClubFee(fullAmount);
    }
  };

  const handleCouponField = (ev) => {
    setCouponInput(ev.target.value);
  };

  const updateFunnelAndIntent = (data) => {
    dispatch(
      setPaymentData({
        ...FunnelData,
        PaymentData: data,
        old_PaymentData: FunnelData.PaymentData,
        club: joinClub,
        remaining_discount: currentUser.membership?.remaining_discount || 4,
        dispatch_id: "ps174",
      })
    )
    updateIntent(data, FunnelData.PaymentOption)
  }

  useEffect(() => {
    const updatedFunnelData = joinClub ?
      {
        "total_margin": FunnelData.PaymentData.total_margin - 500,
        "rest_to_pay": FunnelData.PaymentData.rest_to_pay,
        "pay_now": FunnelData.PaymentData.pay_now - 500,
        "fee": 0,
        "joinClub": true,
        "discountable": FunnelData.PaymentData.discountable
      }
      : joinClub !== undefined && !joinClub ?
        {
          "total_margin": FunnelData.PaymentData.total_margin + 500,
          "rest_to_pay": FunnelData.PaymentData.rest_to_pay,
          "pay_now": FunnelData.PaymentData.pay_now + 500,
          "fee": FunnelData.old_PaymentData.fee,
          "joinClub": false,
          "discountable": FunnelData.PaymentData.discountable
        } : null
    updatedFunnelData &&
      updateFunnelAndIntent(updatedFunnelData)
  }, [joinClub])

  const doJoinClub = () => {
    setJoinClub(!joinClub)
  }

  useEffect(() => {
    ((currentUser.membership === null || !currentUser.membership?.status === 'paid') && costlessPayment) && joinClub &&
      setJoinClub(false)
  }, [costlessPayment])

  const updateIntent = (payment_data, payment_option) => {
    FunnelData.intent?.id &&
      axios.put(`${BASE_URL}/api/web/funnel/intents/${FunnelData.intent.id}`, {
        new_amount: toPayNowAmount(payment_data),
        reservation_code: reservation_code,
        payment_option: payment_option,
        club: joinClub
      }).then((response) => {
        if (response.status === 200) {
          elements && elements.fetchUpdates()
        }
      })
  };

  useEffect(() => {
    FunnelData.intent
      ? updateIntent(FunnelData.PaymentData, FunnelData.PaymentOption)
      : null;
  }, []);

  const handleApplyCoupon = (funnel_data, couponInput) => {
    analyticsTrack("Press ApplyCoupon", { input: couponInput });

    axios
      .get(`${BASE_URL}/api/main/funnel/coupons/${couponInput}`, {
        params: { reservation_code: reservation_code, payment_type: FunnelData.PaymentOption },
      })
      .then((response) => {
        if (response.status === 200) {
          setCouponError(false);
          dispatch(
            setPaymentData({
              ...funnel_data,
              PaymentData: response.data.payment_data,
              old_PaymentData: funnel_data.PaymentData,
              dispatch_id: "ps174",
            })
          );
          updateIntent(response.data.payment_data, FunnelData.PaymentOption);
        }
      })
      .catch(function (error) {
        setCouponError(error.response.data.message);
      });
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const stopLoadingOnError = (error) => {
    dispatch(
      setPaymentData({
        ...FunnelData,
        loading: false,
        dispatch_id: "ps208",
      })
    );
    setPaymentLoad(false);
    setCardError(error.message);
  };

  function handleStripeJsResult(result) {
    if (result.error) {
      // Show error in payment form
      stopLoadingOnError({ message: I18n.t("payments_funnel__incorrect_secure") });
    } else {
      // The card action has been handled
      // The PaymentIntent can be confirmed again on the server

      stripePaymentMethodHandler(
        {
          paymentMethod: { id: result.paymentIntent.payment_method },
        },
        false,
        result.paymentIntent.id
      );
    }
  }

  const doPoll = (charge_id) => {
    axios
      .get(`${BASE_URL}/transaction_charge_get.json`, {
        params: { id: charge_id },
      })
      .then((response) => {
        if (response.data.status === "succeeded") {
          axios
            .get(`${BASE_URL}/generate_payment_response`, {
              params: { stripe_id: response.data.payload.id },
            })
            .then((response) => {
              if (response.status === 200) {
                window.location.replace(`${response.data.url}`);
              }
            });
        } else if (response.data.status === "pending") {
          setTimeout(function () {
            doPoll(charge_id);
          }, 1000);
        } else if (response.data.status === "requires_action") {
          // Use Stripe.js to handle required card action
          stripe
            .handleCardAction(response.data.payload.client_secret)
            .then(handleStripeJsResult);
        }
      }).catch(function (error) {

        if (error.response.data.error_message.includes("security code")) {
          stopLoadingOnError({ message: I18n.t("payments_funnel__incorrect_cvc") });
        } else if (error.response.data.error_message.includes("card")) {
          stopLoadingOnError({ message: I18n.t("payments_funnel__declined_card") });
        }
        else {
          stopLoadingOnError({ message: I18n.t("payments_funnel__incorrect_secure") });
        }

      });
  };

  const doCostlessPoll = (charge_id) => {
    axios
      .get(`${BASE_URL}/transaction_charge_get.json`, {
        params: { id: charge_id },
      })
      .then((response) => {
        if (response.data.status === "succeeded") {
          axios
            .get(`${BASE_URL}/generate_costless_payment_response`, {
              params: { charge_id: charge_id },
            })
            .then((response) => {
              if (response.status === 200) {
                window.location.replace(`${response.data.url}`);
              }
            });
        } else if (response.data.status === "pending") {
          setTimeout(function () {
            doPoll(charge_id);
          }, 1000);
        } else {
          stopLoadingOnError({ message: "something went wrong" });
        }
      })
  };

  function stripePaymentMethodHandler(paymentMethod, error, payment_intent_id = null) {
    if (error) {
      stopLoadingOnError({ message: error.message });
    } else {
      fetch("/ajax/confirm_payment", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          payment_method_id: paymentMethod.id,
          payment_intent_id: payment_intent_id,
          reservation_code: reservation_code,
          payment_option: selectedPaymentOption(),
          club: joinClub || FunnelData.club,
        }),
      })
        .then(function (response) {
          if (response.status === 200) {
            response.json().then(function (json) {
              doPoll(json.id);
            });
          } else if (response.status === 307) {
            response.json().then(function (json) {
              stopLoadingOnError({ message: json.error_message });
              setTimeout(function () {
                location.href = location.origin + json.redirect_url;
              }, 6000);
            });
          }
        })
        .catch(function (error) {
          stopLoadingOnError({ message: "something went wrong" });
        });
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    dispatch(
      setPaymentData({
        ...FunnelData,
        loading: true,
        dispatch_id: "ps327",
      })
    );

    if (!paymentLoad && !costlessPayment) {
      setPaymentLoad(true);

      if (!stripe || !elements) {
        return;
      }

      const { error: submitError } = await elements.submit();
      if (submitError) {
        stopLoadingOnError(submitError);
        return;
      }

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        elements,
      });

      stripePaymentMethodHandler(paymentMethod);
    } else if (costlessPayment) {
      setPaymentLoad(true);

      fetch("/ajax/confirm_payment", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          reservation_code: reservation_code,
          payment_option: selectedPaymentOption(),
          club: joinClub || FunnelData.club
        }),
      })
        .then(function (result) {
          if (result.status === 200) {
            result.json().then(function (json) {
              doCostlessPoll(json.id);
            });
          } else if (result.status === 307) {
            result.json().then(function (json) {
              stopLoadingOnError({ message: json.error_message });
              setTimeout(function () {
                location.href = location.origin + json.redirect_url;
              }, 6000);
            });
          }
        })
        .catch(function (error) {
          stopLoadingOnError({ message: "something went wrong" });
        });
    }
  };

  const handlePaymentSet = () => {
    if (stripe && FunnelData.PaymentData.pay_now) {
      const pr = stripe.paymentRequest({
        country: currentCountry,
        currency: "eur",
        total: {
          label: "total",
          amount: toPayNowAmount(FunnelData.PaymentData),
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });
      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);
        } else {
          console.error("Payment Request API not available");
        }
      }).catch(error => {
        console.error("Error checking canMakePayment", error);
      });
    } else {
      console.error("Stripe not initialized or pay_now is false", stripe, FunnelData.PaymentData.pay_now);
    }
  };

  useEffect(() => {
    if (stripe && FunnelData.PaymentData) {
      handlePaymentSet();
    }
  }, [stripe, FunnelData.PaymentData]);

  const handleLoadSetter = (status) => {
    dispatch(
      setPaymentData({
        ...FunnelData,
        loading: status,
        dispatch_id: "ps397",
      })
    );
  };

  const doPollExplorerWalletsPay = () => {
    axios
      .get(`${BASE_URL}/api/main/funnel/loadings/${reservation_code}`)
      .then((response) => {
        if (response.status === 200) {
          window.location.replace(`${response.data.url}`);
        } else if (response.status === 202) {
          setTimeout(function () {
            doPollExplorerWalletsPay();
          }, 1000);
        } else {
          stopLoadingOnError({ message: "something went wrong" });
        }
      });
  };

  useEffect(() => {
    props.brand?.data !== null &&
      props.brand.data.attributes.discount &&
      handleApplyCoupon(FunnelData, props.brand.data.attributes.discount);
  }, [props.brand]);

  useEffect(() => {
    !paymentLoad && handleWalletPaymentButtonClick(FunnelData.PaymentData, FunnelData.PaymentOption)
  }, [FunnelData.PaymentOption]);

  useEffect(() => {
    paymentRequest &&
      paymentRequest.on("paymentmethod", async (ev) => {
        // Confirm the PaymentIntent without handling potential next actions (yet).
        handleLoadSetter(true);

        const { paymentIntent, error: confirmError } =
          await stripe.confirmCardPayment(
            clientSecret,
            { payment_method: ev.paymentMethod.id },
            { handleActions: false }
          );

        if (confirmError) {
          // Report to the browser that the payment failed, prompting it to
          // re-show the payment interface, or show an error message and close
          // the payment interface.
          setPaymentLoad(false);

          ev.complete("fail");
        } else {
          // Report to the browser that the confirmation was successful, prompting
          // it to close the browser payment method collection interface.
          ev.complete("success");
          doPollExplorerWalletsPay();
          // Check if the PaymentIntent requires any actions and if so let Stripe.js
          // handle the flow. If using an API version older than "2019-02-11"
          // instead check for: `paymentIntent.status === "requires_source_action"`.
          if (paymentIntent.status === "requires_action") {
            // Let Stripe.js handle the rest of the payment flow.
            const { error } = await stripe.confirmCardPayment(clientSecret);
            if (error) {
              // The payment failed -- ask your customer for a new payment method.
              setPaymentLoad(false);
            } else {
              // The payment has succeeded.
            }
          } else {
            // The payment has succeeded.
          }
        }
      });
  }, [paymentRequest]);

  const updateExplorerWalletAmount = (payment_data, payment_option) => {
    paymentRequest &&
      paymentRequest.update({
        total: {
          label: "total",
          amount: Math.ceil(toPayNowAmount(payment_data)),
        },
      });
    updateIntent(payment_data, payment_option);
  };

  const handleWalletPaymentButtonClick = (payment_data, payment_option) => {
    analyticsTrack("Press explorer wallet payment button", {});
    updateExplorerWalletAmount(payment_data, payment_option);
  };

  const discountCouponField = () => {
    return (
      <React.Fragment>
        <div className={classes.standardWrap}>
          <input
            type="text"
            className="form-control"
            onChange={(ev) => handleCouponField(ev)}
            onBlur={(ev) =>
              analyticsTrack("Filled Coupon input field", {
                input: ev.target.value,
              })
            }
            onFocus={() => analyticsTrack("Entered Coupon input field", {})}
            id="subscription_stripe_coupon"
          ></input>

          <div
            id="apply_coupon"
            onClick={() => handleApplyCoupon(FunnelData, couponInput)}
            style={couponField}
          >
            {I18n.t("aplicar")}
          </div>
        </div>
      </React.Fragment>
    );
  };

  const discountCouponOption = () => {
    return (
      <React.Fragment>
        <div id="coupons_field">
          <label
            style={couponMessage}
            onClick={() => handleCouponQuestionClick()}
          >
            {I18n.t("title_coupon")}
          </label>
          <br></br>
          {showCouponField && discountCouponField()}

          {couponError && <p className={classes.error}>{couponError}</p>}

          {FunnelData.is_referral_active && (
            <p id="referral_coupon_warning" style={referralWarning}>
              {I18n.t("referral_coupon_warning")}{" "}
            </p>
          )}
        </div>
      </React.Fragment>
    );
  };

  const handleCouponQuestionClick = () => {
    setShowCouponField(!showCouponField);
    analyticsTrack("Press CouponQuestion", {});
  };

  const paymentElementOptions = {
    defaultValues: {
      billingDetails: {
        address: {
          country: currentCountry
        }
      }
    }
  }

  const allowClub = () => {
    return FunnelData.PaymentData.pay_now >= 550 && FunnelData.PaymentData.discountable
  }

  return (
    <React.Fragment>

      <PaymentOptions {...props} />

      {/**((currentUser.membership == null || currentUser.membership?.status != 'paid') && !costlessPayment && allowClub()) &&
        <React.Fragment>
          <div className="col-md-12 col-sm-12 col-xs-12 no-padding" style={{ backgroundColor: "#F7EB67", marginTop: "20px", padding: "10px 20px", borderRadius: 25, display: 'flex', alignItems: 'center' }}>
            <div style={{ flex: "0 1 0" }}>
              <label className="switch">
                <input
                  type="checkbox"
                  name="wallet_friendly"
                  value="true"
                  onClick={() => doJoinClub()}
                  checked={joinClub}
                />
                <span className="slider round"></span>
              </label>
            </div>
            <div style={{ flex: "1 1 0px", flexFlow: "row", display: "flex", paddingLeft: "20px" }}>
              <div dangerouslySetInnerHTML={{ __html: I18n.t("club.join_html", { currency: currencyToSymbol('eur') }) }} />
            </div>
          </div>

          <div className="col-md-12 col-sm-12 col-xs-12 no-padding"><br /></div>
        </React.Fragment>
      **/}

      <form onSubmit={handleSubmit}>
        <div className={classes.walletWrap}>
          {!costlessPayment &&
            <React.Fragment>
              {!walletPartialPayment && (
                <p className={classes.bold}>
                  {I18n.t("payments_funnel__pay_with")}
                </p>
              )}
              {walletPartialPayment && (
                <p className={classes.bold}>{I18n.t("payments_funnel__topay")}</p>
              )}

              <div>
                <PaymentElement id="payment-element" options={paymentElementOptions}
                  onBlur={() => analyticsTrack("Filled CardElement", {})}
                  onFocus={() => analyticsTrack("Entered CardElement", {})}
                  style={{ height: "auto !important", padding: "0 !important" }} />
                {cardError && <p className={classes.error}>{cardError}</p>}
              </div>
            </React.Fragment>
          }


          <div className={classes.separator}></div>
          <div
            className={clsx("col-md-12 col-xs-12 no-padding", classes.noFloat)}
          >
            {(props.brand.data == null ||
              !props.brand.data.attributes.discount) &&
              currentUser.role !== "travel_agent" &&
              (!joinClub && !currentUser.membership?.active) &&
              discountCouponOption()}

            <br></br>
            <React.Fragment>
              <div className={classes.standardWrap}>
                <input
                  id="reservation_conditions_payment"
                  type="checkbox"
                  className={classes.paymentCheckBox}
                  name="reservation_conditions"
                  ref={acceptConditions}
                  onClick={() =>
                    analyticsTrack("Press reservation_conditions", {
                      checked: acceptConditions.current.checked,
                    })
                  }
                  required
                ></input>

                <label
                  dangerouslySetInnerHTML={{
                    __html: I18n.t("condiciones_checkout_html"),
                  }}
                  htmlFor="reservation_conditions_payment"
                  className={classes.labelPaymentCheckBox}
                />
              </div>
            </React.Fragment>
            <br></br>
            {(FunnelData.selectedPaymentMethod === cardPaymentOption ||
              costlessPayment) && (
                <button
                  className={clsx(
                    "btn",
                    props.brand?.data != null ? classes.primaryColor : "btn-primary"
                  )}
                  type="submit"
                  disabled={!FunnelData.accept_terms && paymentLoad}
                  style={payNowButton}
                  onClick={() => analyticsTrack("Press pay_button credit card", {})}
                >
                  <span id="pay_now_btn">
                    {" "}
                    {I18n.t("pay_button", {
                      price: to_eur(toPayNowAmount(FunnelData.PaymentData)),
                      currency: currencyToSymbol(FunnelData.ProductData.currency),
                    })}{" "}
                  </span>
                </button>
              )}


            <div className="clearfix" />
          </div>
        </div>

        

      </form>
    </React.Fragment>
  );
}

export default PaymentStep;
