import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import AreaHomeSlideProductBlock from "./areaHome/AreaHomeSlideProductBlock";
import AreaHeader from "./areaHome/AreaHeader";
import AreaHeaderMobile from "./areaHome/AreaHeaderMobile";
import { makeStyles } from "@material-ui/core/styles";
import { capitalizeFirstLetter } from "../../helpers/base.js";
import { createMedia } from "@artsy/fresnel";
import { useSelector, useDispatch } from "react-redux";
import I18n from "../../i18n-js/index.js.erb";
import slugify from "react-slugify";
import SkeletonsSlideProductBlock from "./skeletons/SkeletonsSlideProductBlock";
import clsx from "clsx";
import NavDesktop from "./nav/NavDesktop";
import CategoryBlock from "./components/CategoryBlock";
import { Skeleton } from "@mui/material";
import StarRatings from "react-star-ratings";
import { setFiltersResults } from "../../components/redux/actions/actions";

function AreaHome(props) {
  const dispatch = useDispatch();

  const selectedArea = capitalizeFirstLetter(
    useSelector((state) => state.area)
  );
  const areaHomeProducts = useSelector((state) => state.areaHomeProducts);
  const filtersResults = useSelector((state) => state.filtersResults);
  const csrfToken = useSelector((state) => state.csrfToken);
  const currentLocale = useSelector((state) => state.currentLocale);
  const currentUser = useSelector((state) => state.currentUser);
  const [selectedAreaName, setSelectedAreaName] = useState();
  const [cols, setCols] = useState();
  const [icon, setIcon] = useState();
  const [image, setImage] = useState();
  const [description, setDescription] = useState();
  const [categories, setCategories] = useState();
  const [rating, setRating] = useState(0);
  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
  axios.defaults.withCredentials = true;

  const [ready, setReady] = useState(false);

  useEffect(() => {
    setReady(true);
  }, []);

  const useStyles = makeStyles((theme) => ({
    slidingPane: {
      paddingTop: "5px",
      backgroundColor: "white",
      width: "100%",
      height: "fit-content",
      position: "relative",
    },
    root: {
      backgroundColor: "#f7eb67",
    },
    container: {
      marginBottom: "-10px",
      padding: "3% 5.5% 100px",
    },
    greyBlock: {
      backgroundColor: "#F9F9F9",
    },
    provinceList: {
      display: "flex",
      flexWrap: "wrap",
      listStyle: "none",
      margin: "3rem -.5rem 0",
      padding: "0",
      justifyContent: "space-between",
    },
    provinceListMobile: {
      display: "flex",
      flexWrap: "no-wrap",
      flexDirection: "column",
      listStyle: "none",
      margin: "3rem -.5rem 0",
      padding: "0",
      justifyContent: "space-between",
    },
    categoryListMobile: {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      listStyle: "none",
      margin: "3rem -.5rem 0",
      padding: "0",
      justifyContent: "space-between",
    },
    category: {
      maxWidth: "100%",
      margin: "0 6vw",
      clear: "both",
    },
    uspText: {
      fontFamily: "TobiasTRIAL-Regular",
      fontSize: "18px",
      maxWidth: "90%",
    },
    uspBlock: {
      color: "#7d2647",
      padding: "20px 40px",
      width: "32%",
      maxWidth: "32%",
      backgroundColor: "#F4A583",
      borderRadius: "10px",
      border: "1px solid #7d2647",
    },
    uspBlockMobile: {
      color: "#7d2647",
      padding: "20px 40px",
      width: "100%%",
      maxWidth: "100%%",
      marginBottom: "10px",
      backgroundColor: "#F4A583",
      borderRadius: "10px",
      border: "1px solid #7d2647",
    },
    uspBlockImage: {
      paddingTop: "20px",
      width: "75%",
    },
  }));

  const classes = useStyles();

  const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
      sm: 0,
      md: 768,
      lg: 1024,
      xl: 1192,
    },
  });

  const clickStars = () => {
    window.location = `http://www.hotelbreak.com/${currentLocale}/reviews/new?reservation_code=${currentUser?.pending_review?.data.attributes.reservationCode}`;
  };

  useEffect(() => {
    let isMounted = true;

    if (!filtersResults && selectedArea && isMounted) {
      axios
        .get("/api/main/filters.json", {
          params: {
            area: selectedArea,
          },
        })
        .then((response) => {
          if (isMounted) {
            dispatch(setFiltersResults(response.data.results));
          }
        });
    }

    return () => {
      isMounted = false;
    };
  }, [selectedArea]);

  useEffect(() => {
    axios
      .post("/api/main/provinces/show.json", {
        area: selectedArea && selectedArea,
        locale: currentLocale,
      })
      .then((response) => {
        if (response.data.icon !== null) {
          setIcon(response.data.icon);
          setImage(response.data.image);
          setDescription(response.data.description);
          setSelectedAreaName(response.data.name);
          const cats = response.data.categories.data;
          // cats.unshift({
          //   id: "100",
          //   type: "Category",
          //   attributes: {
          //     id: 100,
          //     name: "GiftCard",
          //     slug: "giftcard",
          //     description: "We all dream of a Hotelbreak gift card",
          //     photo:
          //       "https://hotelbreak.s3.eu-west-3.amazonaws.com/giftcardhome25.png",
          //     category_block: I18n.t("gift_card_block"),
          //   },
          // });
          setCategories(cats);
        }
      });
  }, []);

  const productsBlocks = (reco, cols, device) => {
    var content = [];
    var cats;
    reco
      ? (cats = areaHomeProducts.payload.filter((x) => x.slug == "recommended"))
      : (cats = areaHomeProducts.payload.filter(
          (x) => x.slug != "recommended"
        ));
    cats.forEach((product_list) => {
      if (product_list.category.length > 0) {
        const productBlocks = (
          <AreaHomeSlideProductBlock
            key={product_list.slug}
            selector={product_list.slug}
            products={product_list.category}
            title={product_list.title}
            category_description={product_list.category_description}
            emoji={product_list.emoji}
            listCols={cols}
            area={slugify(selectedArea)}
            areaName={selectedAreaName}
            show_all_link={
              product_list.slug.indexOf("recommended") >= 0 ? false : true
            }
            locale={currentLocale}
            selectedDate={props.selectedDate}
            selectedPax={props.selectedPax}
            todayNotAvailable={props.todayNotAvailable}
            device={device}
            yellow={product_list.slug == "recommended"}
          />
        );

        content.push(productBlocks);
      }
    });

    return content;
  };

  const areaHome = (col) => {
    return (
      <div style={{ visibility: ready ? "visible" : "hidden" }}>
        {col < 3 ? (
          <AreaHeaderMobile
            icon={icon}
            area={selectedArea}
            areaName={selectedAreaName}
            image={image}
            todayNotAvailable={props.todayNotAvailable}
          />
        ) : (
          <>
            <NavDesktop source={"area_home"} areaName={selectedAreaName} />
            <AreaHeader
              icon={icon}
              area={selectedArea}
              image={image}
              areaName={selectedAreaName}
              todayNotAvailable={props.todayNotAvailable}
            />
          </>
        )}
        <div className={classes.slidingPane}>
          {currentUser?.pending_review &&
            currentUser?.pending_review?.data?.attributes && (
              <div className={"row"}>
                <div className="container">
                  <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
                    {I18n.t("dejanos_tu_opinion")}
                  </h2>
                  <div
                    className="col-md-4 col-md-offset-2 col-xs-12 col-xs-offset-0"
                    style={{ textAlign: "center", marginBottom: "50px" }}
                  >
                    <img
                      src={`https://www.hotelbreak.com${currentUser.pending_review.data.attributes.blockPhoto}`}
                      height={200}
                      style={{
                        objectFit: "cover",
                        borderRadius: 5,
                        maxWidth: "100%",
                      }}
                    />
                  </div>
                  <div className="col-md-4 col-xs-12">
                    <strong style={{ fontSize: 20 }}>
                      {I18n.t("email_review_email_text_3", {
                        experience:
                          currentUser.pending_review.data.attributes
                            .productName,
                        hotel:
                          currentUser.pending_review.data.attributes.hotelName,
                      })}
                    </strong>
                    <br />
                    <br />
                    <br />
                    <StarRatings
                      starRatedColor="#F7EB67"
                      starHoverColor="#F7EB67"
                      isAggregateRating
                      numberOfStars={5}
                      name={"fakereview"}
                      starDimension={"45px"}
                      selected={rating}
                      changeRating={() => clickStars()}
                    />
                  </div>
                </div>
              </div>
            )}
          <div className={classes.category}>
            {selectedAreaName ? (
              <h2>
                {I18n.t("area_home.citys_favorites", {
                  city: selectedAreaName,
                })}
              </h2>
            ) : (
              <h2>
                <Skeleton variant="text" width={200} />
              </h2>
            )}
            {categories ? (
              <ul
                className={
                  col < 3 ? classes.categoryListMobile : classes.provinceList
                }
              >
                {categories.map((category) => {
                  return (
                    <>
                      <CategoryBlock
                        image={category.attributes.photo}
                        text={category.attributes.category_block}
                        slug={category.attributes.slug}
                        large={col < 3 ? false : true}
                      />
                    </>
                  );
                })}
              </ul>
            ) : (
              <SkeletonsSlideProductBlock
                listCols={col}
                device={col < 3 ? "mobile" : "desktop"}
              />
            )}
          </div>
          {areaHomeProducts.status && props.reloaded ? (
            productsBlocks(true, col, col < 3 ? "mobile" : "desktop")
          ) : (
            <>
              <SkeletonsSlideProductBlock
                listCols={col}
                device={col < 3 ? "mobile" : "desktop"}
              />
            </>
          )}
          {areaHomeProducts.status && props.reloaded ? (
            productsBlocks(false, col, col < 3 ? "mobile" : "desktop")
          ) : (
            <>
              <SkeletonsSlideProductBlock
                listCols={col}
                device={col < 3 ? "mobile" : "desktop"}
              />
              <SkeletonsSlideProductBlock
                listCols={col}
                device={col < 3 ? "mobile" : "desktop"}
              />
            </>
          )}
          <div className={classes.category} style={{ marginTop: "50px" }}>
            <h2>{I18n.t("area_home.why_hotelbreak")}</h2>
            <ul
              className={
                col < 3 ? classes.provinceListMobile : classes.provinceList
              }
            >
              <li
                className={col < 3 ? classes.uspBlockMobile : classes.uspBlock}
              >
                <div className="col-md-2 col-xs-2 no-padding">
                  <img
                    src="https://hotelbreak.s3.eu-west-3.amazonaws.com/v2/ok_hands.png"
                    className={classes.uspBlockImage}
                  />
                </div>
                <div
                  className={clsx(
                    "col-md-10 col-xs-10",
                    col < 3 && "no-padding"
                  )}
                >
                  <p className={classes.uspText}>
                    {I18n.t("area_home.usp_1_title")}
                  </p>
                  <p>{I18n.t("area_home.usp_1_text")}</p>
                </div>
              </li>
              <li
                className={col < 3 ? classes.uspBlockMobile : classes.uspBlock}
              >
                <div className="col-md-2 col-xs-2 no-padding">
                  <img
                    src="https://hotelbreak.s3.eu-west-3.amazonaws.com/v2/clap_hands.png"
                    className={classes.uspBlockImage}
                  />
                </div>
                <div
                  className={clsx(
                    "col-md-10 col-xs-10",
                    col < 3 && "no-padding"
                  )}
                >
                  <p className={classes.uspText}>
                    {I18n.t("area_home.usp_2_title")}
                  </p>
                  <p>{I18n.t("area_home.usp_2_text")}</p>
                </div>
              </li>
              <li
                className={col < 3 ? classes.uspBlockMobile : classes.uspBlock}
              >
                <div className="col-md-2 col-xs-2 no-padding">
                  <img
                    src="https://hotelbreak.s3.eu-west-3.amazonaws.com/v2/wallet.png"
                    className={classes.uspBlockImage}
                  />
                </div>
                <div
                  className={clsx(
                    "col-md-10 col-xs-10",
                    col < 3 && "no-padding"
                  )}
                >
                  <p className={classes.uspText}>
                    {I18n.t("area_home.usp_3_title")}
                  </p>
                  <p>{I18n.t("area_home.usp_3_text")}</p>
                </div>
              </li>
            </ul>
          </div>
          {description && (
            <div className={clsx(classes.container, classes.greyBlock)}>
              <div dangerouslySetInnerHTML={{ __html: description }} />
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <MediaContextProvider>
        <Media at="sm">{areaHome(1.3)}</Media>
        <Media at="md">{areaHome(2.3)}</Media>
        <Media at="lg">{areaHome(3.3)}</Media>
        <Media greaterThanOrEqual="xl">{areaHome(4.3)}</Media>
      </MediaContextProvider>
    </div>
  );
}

export default AreaHome;
